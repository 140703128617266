<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Soru Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" name="question">
                <v-textarea
                  slot-scope="{ attrs, listeners }"
                  v-bind="attrs"
                  v-on="listeners"
                  label="Soru"
                  v-model="form.question"
                ></v-textarea>
              </form-group>
            </v-row>
            <div>
              Seçenekleri girip, doğru seçeneğin yanındaki kutucuğu işaretleyin.
            </div>
            <v-row v-for="(option, i) in form.options" :key="i">
              <form-group cols="11" :validator="$v.form.options.$each[i].text">
                <v-text-field
                  slot-scope="{ attrs, listeners }"
                  v-bind="attrs"
                  v-on="listeners"
                  :label="'Seçenek ' + (i + 1)"
                  v-model="form.options[i].text"
                ></v-text-field>
              </form-group>
              <form-group
                cols="1"
                :validator="$v.form.options.$each[i].isCorrect"
              >
                <v-checkbox
                  slot-scope="{ attrs, listeners }"
                  v-bind="attrs"
                  v-on="listeners"
                  v-model="form.options[i].isCorrect"
                  @change="onOptionSelected($event, i)"
                ></v-checkbox>
              </form-group>
            </v-row>
            <v-row>
              <form-group xs="6" name="level">
                <v-select
                  slot-scope="{ attrs, listeners }"
                  v-bind="attrs"
                  v-on="listeners"
                  label="Zorluk Seviyesi"
                  v-model="form.level"
                  :items="levels"
                ></v-select>
              </form-group>
              <form-group xs="6">
                <v-checkbox
                  slot-scope="{ attrs, listeners }"
                  v-bind="attrs"
                  v-on="listeners"
                  label="Aktif"
                  v-model="form.status"
                ></v-checkbox>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['visible'],

  mixins: [validationMixin],

  validations: {
    form: {
      question: { required },
      options: {
        $each: {
          text: { required }
        }
      }
    }
  },

  data() {
    return {
      loading: true,
      form: {
        question: '',
        options: [
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          }
        ],
        level: 1,
        status: false
      },
      levels: [
        {
          text: 'Kolay',
          value: 1
        },
        {
          text: 'Orta',
          value: 2
        },
        {
          text: 'Zor',
          value: 3
        }
      ]
    };
  },

  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (this.$v.$anyError) {
          this.$store.dispatch('setToast', {
            text: 'Alanları düzgün doldurun!',
            color: 'danger'
          });
          return false;
        }

        if (!this.form.options.some(o => o.isCorrect)) {
          this.$store.dispatch('setToast', {
            text: 'Doğru seçeneği işaretleyin!',
            color: 'danger'
          });
          return false;
        }

        await this.axios.post('admin/quizQuestions', this.form);
        this.close();
        this.$emit('done');
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde eklendi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Ekleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    onOptionSelected(value, index) {
      if (value) {
        this.form.options.map((option, i) => {
          if (index !== i) option.isCorrect = false;
        });
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {
        question: '',
        options: [
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          }
        ],
        level: 1,
        status: false
      };
    }
  },
  watch: {}
};
</script>
