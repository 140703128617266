<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="gutter-b">
    <card title="Bilgi Yarışması Soru Listesi">
      <v-menu slot="toolbar" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined dark v-on="on">
            İşlemler
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="questionAddModal = true">
            Soru Ekle
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="row">
        <div class="col-md-12">
          <v-text-field
            :value="search"
            @change="v => (search = v)"
            append-icon="mdi-magnify"
            label="Soru ara"
            single-line
            style="padding: 0; margin: 0"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="questions"
        :loading="loading"
        :server-items-length="total"
        :options.sync="options"
        :search="search"
        multi-sort
      >
        <template v-slot:item.level="{ item }">
          <span v-if="item.level === 1">Kolay</span>
          <span v-else-if="item.level === 2">Normal</span>
          <span v-else-if="item.level === 3">Zor</span>
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status ? 'Aktif' : 'Pasif' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="$refs.questionEditModal.open(item.id)"
          >mdi-pencil</v-icon
          >

          <v-icon
            small
            @click="
              deletePromt = true;
              deleteId = item.id;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </card>
    <DeletePromt
      :visible="deletePromt"
      @close="deletePromt = false"
      @done="deleteQuestion()"
    >
    </DeletePromt>
    <QuestionAdd
      :visible="questionAddModal"
      @close="questionAddModal = false"
      @done="getQuestions()"
    ></QuestionAdd>
    <QuestionEdit ref="questionEditModal" @done="getQuestions()"></QuestionEdit>
  </div>
</template>

<script>
import DeletePromt from '@/view/content/components/DeletePromt';
import QuestionAdd from './QuestionAdd';
import QuestionEdit from './QuestionEdit';

export default {
  components: { QuestionAdd, QuestionEdit, DeletePromt },
  data() {
    return {
      headers: [
        { text: 'Soru', value: 'question' },
        { text: 'Derecesi', value: 'level' },
        { text: 'Durumu', value: 'status' },
        { text: 'İşlemler', value: 'actions', sortable: false }
      ],
      questions: [],
      options: {
        itemsPerPage: 10
      },
      questionAddModal: false,
      deletePromt: false,
      deleteId: null,
      treeSearch: '',
      search: '',
      loading: true,
      total: 0
    };
  },
  mounted() {
    this.getQuestions();
  },
  methods: {
    async getQuestions() {
      try {
        const {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          search,
          filter
        } = this.requestQuery;

        const sort = {};
        sortBy.map((item, index) => {
          const array = item.split('.');
          if (array.length === 1) {
            sort[item] = sortDesc[index] ? 'desc' : 'asc';
          } else {
            sort[array[0]] = {};
            sort[array[0]][array[1]] = sortDesc[index] ? 'desc' : 'asc';
          }
        });

        const { data } = await this.axios.get('admin/quizQuestions/list', {
          params: {
            page: {
              size: itemsPerPage,
              number: page
            },
            sort,
            search: {
              question: search
            },
            filter
          }
        });
        this.questions = data.rows;
        this.total = data.count;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteQuestion() {
      try {
        await this.axios.delete('admin/quizQuestions/' + this.deleteId);

        this.getQuestions();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    }
  },
  computed: {
    requestQuery() {
      return {
        ...this.options,
        search: this.search
      };
    }
  },
  watch: {
    requestQuery: {
      handler: 'getQuestions',
      deep: true
    }
  }
};
</script>
