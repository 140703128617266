var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gutter-b"},[_c('card',{attrs:{"title":"Bilgi Yarışması Soru Listesi"}},[_c('v-menu',{attrs:{"slot":"toolbar","offset-y":""},slot:"toolbar",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":"","dark":""}},on),[_vm._v(" İşlemler ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.questionAddModal = true}}},[_vm._v(" Soru Ekle ")])],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-text-field',{staticStyle:{"padding":"0","margin":"0"},attrs:{"value":_vm.search,"append-icon":"mdi-magnify","label":"Soru ara","single-line":""},on:{"change":function (v) { return (_vm.search = v); }}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.questions,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"search":_vm.search,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level === 1)?_c('span',[_vm._v("Kolay")]):(item.level === 2)?_c('span',[_vm._v("Normal")]):(item.level === 3)?_c('span',[_vm._v("Zor")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? 'Aktif' : 'Pasif')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$refs.questionEditModal.open(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.deletePromt = true;
            _vm.deleteId = item.id;}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('DeletePromt',{attrs:{"visible":_vm.deletePromt},on:{"close":function($event){_vm.deletePromt = false},"done":function($event){return _vm.deleteQuestion()}}}),_c('QuestionAdd',{attrs:{"visible":_vm.questionAddModal},on:{"close":function($event){_vm.questionAddModal = false},"done":function($event){return _vm.getQuestions()}}}),_c('QuestionEdit',{ref:"questionEditModal",on:{"done":function($event){return _vm.getQuestions()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }