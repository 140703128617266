var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"slot":"toolbar","persistent":"","max-width":"600px"},slot:"toolbar",model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('form-wrapper',{attrs:{"validator":_vm.$v.form}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Soru Ekle")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('form-group',{attrs:{"cols":"12","name":"question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-textarea',_vm._g(_vm._b({attrs:{"label":"Soru"},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}},'v-textarea',attrs,false),listeners))}}])})],1),_c('div',[_vm._v(" Seçenekleri girip, doğru seçeneğin yanındaki kutucuğu işaretleyin. ")]),_vm._l((_vm.form.options),function(option,i){return _c('v-row',{key:i},[_c('form-group',{attrs:{"cols":"11","validator":_vm.$v.form.options.$each[i].text},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-text-field',_vm._g(_vm._b({attrs:{"label":'Seçenek ' + (i + 1)},model:{value:(_vm.form.options[i].text),callback:function ($$v) {_vm.$set(_vm.form.options[i], "text", $$v)},expression:"form.options[i].text"}},'v-text-field',attrs,false),listeners))}}],null,true)}),_c('form-group',{attrs:{"cols":"1","validator":_vm.$v.form.options.$each[i].isCorrect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-checkbox',_vm._g(_vm._b({on:{"change":function($event){return _vm.onOptionSelected($event, i)}},model:{value:(_vm.form.options[i].isCorrect),callback:function ($$v) {_vm.$set(_vm.form.options[i], "isCorrect", $$v)},expression:"form.options[i].isCorrect"}},'v-checkbox',attrs,false),listeners))}}],null,true)})],1)}),_c('v-row',[_c('form-group',{attrs:{"xs":"6","name":"level"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({attrs:{"label":"Zorluk Seviyesi","items":_vm.levels},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},'v-select',attrs,false),listeners))}}])}),_c('form-group',{attrs:{"xs":"6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-checkbox',_vm._g(_vm._b({attrs:{"label":"Aktif"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},'v-checkbox',attrs,false),listeners))}}])})],1)],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Kapat ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.$v.$anyError},on:{"click":_vm.submit}},[_vm._v(" Kaydet ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }